html::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

body {
  margin: 0;
  background-color: white;
}

body::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

ul {
  list-style: none;
  padding: 0;
}

@media (min-width: 768px) {
  body {
    background-color: #E4E7E9;
  }
}

:not(:root):fullscreen::backdrop {
  background-color: #E4E7E9;
}